import PropTypes from "prop-types"
import React from "react"
import FullLogo from "../shared/full-logo"
import "./header.css"
const Header = () => (
  <header className="standard-margin-left standard-margin-right">
    <div className="header-wrapper">
      <FullLogo></FullLogo>
      <span className="float-right">
        {/*
        We will use this once we add more pages
        <Link
          to="/"
          className="link"
        >
          How it Works
        </Link>
        <Link
          to="/"
          className="link"
        >
          Become a Partner
        </Link>
        <Link
          to="/"
          className="link"
        >
          About
        </Link> */}
        <a
          href="mailto:hello@hiperry.com?subject=Demo Request"
          className="green-background"
        >
          Request a RAWR
        </a>
      </span>
    </div>
  </header>
)

export default Header
