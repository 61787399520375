import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TransitionIconThree = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "hand-heart.png" }) {
        childImageSharp {
          fixed(width: 143, height: 116) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      className="standard-margin-top"
      fixed={data.desktop.childImageSharp.fixed}
    />
  )
}

export default TransitionIconThree
