import React from "react"
import FullLogo from "../shared/full-logo"
import "./footer.css"
import Terms from "../../assets/terms.pdf"
import PrivacyPolicy from "../../assets/privacy_policy.pdf"

const Footer = () => (
  <footer className="text-align-right standard-padding-top standard-margin-left">
    <div className="standard-margin-right">
      <FullLogo></FullLogo>
      <p className="body-font">
        <a className="terms float-left" href={Terms} target="_blank">
          Terms{" "}
        </a>
        <a className="terms float-left" href={PrivacyPolicy} target="_blank">
          Privacy
        </a>
        © 2020 Perry Health, Inc. All rights reserved.
      </p>
    </div>
  </footer>
)
export default Footer
