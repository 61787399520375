import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FullLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "perry-logo-2x.png" }) {
        childImageSharp {
          fixed(width: 106, height: 34) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return <Img fixed={data.desktop.childImageSharp.fixed} />
}

export default FullLogo
