import React from "react"
import "./quote.css"
import "../layout/layout.css"
const Quote = () => (
  <div
    className="
width-100
quote-background
quote-wrapper
light-brown-background
standard-padding-top
standard-padding-bottom
"
  >
    <div className="standard-margin-right">
      <h1 className="main-header-font">
        Start changing <br></br>lives with Perry{" "}
      </h1>
      <p
        className="
     quote-font
     width-100
     "
      >
        “This is going to change <br className="mobile-hide"></br> how
        healthcare is <br className="mobile-hide"></br> delivered in the US and{" "}
        <br className="mobile-hide"></br> around the world.”
      </p>

      <div
        className="
     large-padding-bottom
     width-30
     brown-border-bottom"
      ></div>

      <div
        className="
small-header-font
standard-margin-top"
      >
        Dr. Pierre Berry <br></br>
        Crossville, Tennessee
      </div>
      <a
        className="
standard-margin-bottom
brown-background"
        href="mailto:hello@hiperry.com?subject=Demo Request"
      >
        See how it works
      </a>
      <br></br>
      <br></br>
    </div>
  </div>
)
export default Quote
