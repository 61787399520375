import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TransitionIconTwo = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "body-parts.png" }) {
        childImageSharp {
          fixed(width: 197, height: 104) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      className="standard-margin-top"
      fixed={data.desktop.childImageSharp.fixed}
    />
  )
}

export default TransitionIconTwo
