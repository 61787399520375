import React from "react"
import "./roi-calculator.css"

export default class RoiCalculator extends React.Component {
  state = {
    populationSize: undefined,
    roiCalculation: 0,
    calculationReady: true,
    placeholder: "Enter Medicare/MA population",
    placeholderMobile: "Enter Medicare/MA pop.",
  }

  keyPressed = event => {
    if (this.state.populationSize && this.state.populationSize > 0) {
      if (event.key === "Enter" || event.keyCode === 13) {
        this.calculate()
      }
    }
  }

  handleInputChange = event => {
    if (
      event.target &&
      (!event.target.value ||
        (event.target.value && parseInt(event.target.value) <= 99999))
    ) {
      this.setState({
        populationSize: parseInt(event.target.value),
      })
    } else {
      event.preventDefault()
    }
  }
  calculate() {
    this.setState(
      {
        calculationReady: false,
      },
      function() {
        setTimeout(
          () =>
            this.setState({
              calculationReady: true,
              roiCalculation: Math.round(
                this.state.populationSize * 100 * 0.7 * 12
              ).toLocaleString(),
            }),
          800
        )
      }
    )
  }
  render() {
    return (
      <div
        className="
roi-calculator-container
border-radius-p5
standard-margin-left
standard-margin-right
mobile-standard-margin-top
"
      >
        <div className="main-header-font">
          Better care should earn you <br className="mobile-hide"></br>more.
          With Perry, it does.{" "}
        </div>
        <div
          className="
       body-font
       large-margin-top"
        >
          Tap into reimbursement opportunities from CMS and many other private
          payors for remote patient monitoring (RPM), chronic care management
          (CCM), as well as store-and-forward. With lower cost healthcare
          resources such as RN, LPN, and MA time counting towards reimbursement,
          RPM alone enables a sustainable, profitable program.
        </div>

        <div
          className="
 height-auto
 width-100
 large-margin-top
 "
        >
          <div
            className="
 roi-calculator-wrapper
 small-header-font
 mobile-block
 "
          >
            Calculate your estimated RPM revenue
            <br></br>
            <div
              className="
roi-calculator-input
standard-margin-top
border-radius-p5"
            >
              <input
                className="
body-font mobile-hide"
                value={this.state.populationSize}
                onKeyPress={this.keyPressed.bind(this)}
                onChange={this.handleInputChange.bind(this)}
                required
                placeholder={this.state.placeholder}
                type="number"
              />

              <input
                className="
body-font
mobile-show"
                value={this.state.populationSize}
                onKeyPress={this.keyPressed.bind(this)}
                onChange={this.handleInputChange.bind(this)}
                required
                placeholder={this.state.placeholderMobile}
                type="number"
              />
              <span className="small-header-font unit"> patients </span>
              <a
                onClick={this.calculate.bind(this)}
                className={`calculate-button
inline-block
${this.state.populationSize ? "blue-background" : "grey-background"}`}
              >
                {" "}
                Calculate{" "}
              </a>
            </div>
          </div>
          <div
            className="
calculation-output
roi-output-background
main-header-font
largest-line-height
inline-block"
          >
            <span className={!this.state.calculationReady ? "loader" : ""}>
              {this.state.roiCalculation !== 0 && this.state.calculationReady && (
                <span>
                  {" "}
                  ${this.state.roiCalculation}
                  <br></br>
                  annually
                </span>
              )}
              {this.state.roiCalculation == 0 && (
                <span>
                  <br></br>
                </span>
              )}
            </span>
          </div>

          <div className="disclaimer-font standard-margin-top">
            Results from this calculator are only estimates and individual
            results may vary. Please contact Perry Health for further
            individualized guidance. Non-Medicare/MA plans have also approved
            reimbursement for RPM. Calculator assumes monthly co-pay is not a
            barrier to patient adoption (e.g., affordable, covered by Medigap
            policy), assumes average reimbursement is ~$100
            per-patient-per-month, does not take into account unique contracted
            rates, specific regional adjustments, and assumes national average
            of chronic condition prevalence in Medicare population is about 70%.
            It does not include additional sources of revenue from co-billable
            CPT codes (e.g., CCM).
          </div>
        </div>
      </div>
    )
  }
}
