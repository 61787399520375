import React from "react"
import Header from "../header/header.js"
import HeroMain from "./hero-main.js"
import HeroImage from "./hero-image"
import "../layout/layout.css"
const HeroMobile = () => (
  <div>
    <div className="light-brown-background">
      <Header></Header>
      <HeroMain></HeroMain>
    </div>
    <HeroImage></HeroImage>
  </div>
)

export default HeroMobile
