import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "hero-img-2x-cropped.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, maxHeight: 491) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      className="
  mobile-hero-background"
      fluid={data.desktop.childImageSharp.fluid}
    />
  )
}

export default HeroImage
