import React from "react"
import "./transition.css"
import "../layout/layout.css"
import TransitionIconOne from "./transition-icon-one.js"
import TransitionIconTwo from "./transition-icon-two.js"
import TransitionIconThree from "./transition-icon-three.js"
const Transition = () => (
  <div
    className="
transition-margin-top
standard-margin-left 
standard-margin-right
mobile-standard-margin-top"
  >
    <div
      className="
no-brainer
width-50
vertical-align-middle
inline-block 
margin-bottom-auto
text-align-right 
mobile-100
mobile-text-align-center
mobile-no-padding-right
mobile-border-bottom"
    >
      <div
        className="
main-header-font
large-padding-bottom
mobile-font-size
mobile-text-align-left
mobile-standard-margin-top"
      >
        Perry is a
        <br
          className="
mobile-hide"
        ></br>
        &nbsp;no-brainer
        <br
          className="
mobile-hide"
        ></br>
        &nbsp;for you and<br className="mobile-hide"></br>&nbsp;the people
        <br
          className="
mobile-hide"
        ></br>
        &nbsp;you care
        <br
          className="
mobile-hide"
        ></br>
        &nbsp;about<br></br>
      </div>
      <a
        className="
large-margin-top
brown-background
mobile-standard-margin-top 
"
        href="mailto:hello@hiperry.com?subject=Let's Work Together"
      >
        Become a partner
      </a>
    </div>
    <div
      className="
icons-container 
inline-block 
width-50  
vertical-align-middle
mobile-text-align-center 
mobile-100"
    >
      <TransitionIconOne></TransitionIconOne>

      <div className="margin-left-right-auto">
        <div
          className="
small-header-font 
standard-margin-top
"
        >
          No appointment, no problem
        </div>

        <div
          className="
body-font
standard-margin-top
"
        >
          Healthcare should be continuous. Understand conditions and risk
          factors beyond the walls of your office by collecting clinical, social
          determinants, and lifestyle data whenever and wherever you want.
        </div>
      </div>
      <TransitionIconTwo></TransitionIconTwo>
      <div
        className="
small-header-font
standard-margin-top
small-margin-bottom
width-auto
"
      >
        Happier people are healthier people
      </div>
      <div
        className="
body-font
standard-margin-top
width-auto
"
      >
        Understand and address needs in real time. Boost engagement, improve
        clinical outcomes, and be there for your patients like never before.
      </div>
      <br></br>
      <TransitionIconThree className="standard-margin-top"></TransitionIconThree>
      <div
        className="
small-header-font
standard-margin-top
small-margin-bottom
width-auto
"
      >
        Hard work pays off, but so does Perry
      </div>
      <div
        className="
large-padding-bottom
body-font 
width-auto
"
      >
        Perry is easy to set up, easy to use, and easy to get reimbursed for.
        Provide a personalized service while generating revenue.
      </div>
    </div>
  </div>
)
export default Transition
