import React from "react"
import Layout from "../components/layout/layout.js"
import SEO from "../components/seo"
import Transition from "../components/transition/transition.js"
import Hero from "../components/hero/hero.js"
import Quote from "../components/quote/quote.js"

import RoiCalculator from "../components/roi-calculator/roi-calculator.js"
import HeroMobile from "../components/hero/hero-mobile.js"

import "../components/layout/layout.css"

const IndexPage = () => (
  <Layout>
    <SEO />
    <div className="mobile-hide">
      <Hero></Hero>
    </div>
    <div className="mobile-show">
      <HeroMobile></HeroMobile>
    </div>
    <Transition></Transition>
    <RoiCalculator></RoiCalculator>
    <Quote></Quote>
  </Layout>
)

export default IndexPage
