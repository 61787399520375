import React from "react"
import "./hero.css"
const HeroMain = () => (
  <div className="standard-margin-left">
    <h1 className="main-header-font">
      Healthier patients.&nbsp;
      <br></br>Happier providers.
      <br></br>Meet Perry.
    </h1>

    <div className="perry-is body-font">
      Perry is a digital health tool designed to help monitor your patients,
      manage their conditions, and coordinate care and communication. Because
      better care starts with a better way of doing things.
    </div>
    <br></br>

    <div className="small-header-font">Let us know you’re interested!</div>

    <a
      href="mailto:hello@hiperry.com?subject=Reaching Out"
      className="
 blue-background
 standard-margin-top
"
    >
      Contact us
    </a>
    <br></br>
    <br></br>
  </div>
)
export default HeroMain
