import React from "react"
import HeroMain from "./hero-main"
import Header from "../header/header.js"

const Hero = () => (
  <div
    className="
    hero-background
    light-brown-background
    width-100 
    standard-margin-bottom"
  >
    <Header></Header>
    <HeroMain></HeroMain>
  </div>
)
export default Hero
